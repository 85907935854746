$bg-color: #242526;
$dot-color: #4c4e4f;
$dot-size: 0.1rem;
$dot-space: 2rem;
$node-width: 198px;
$primary-graph-color: #4caa4d;
$secondary-graph-color: #883e96;
$graph-red: #ac445c;
$graph-teal: #3e9688;
$graph-yellow: #c1b827;
$connector-color: #606060;
$pane-border: #4c4e4f;
$toolbar-height: 65px;

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: auto;
  //background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
  //linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center, $dot-color;
  //background-size: $dot-space $dot-space;
  background-color: $bg-color;
  background-image: url("img/bg-texture-dark.png");
  background-position: center;
  background-size: 250px 250px;
  background-repeat: repeat;
}


.body::-webkit-scrollbar, html::-webkit-scrollbar {
  display: none;
}


#eternal-root {
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  margin: auto;
  font-family: monaco, Monaco, Consolas, "Source Code Pro", "Lucida Console", monospace, sans-serif;
  color: #323232;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

.hide {
  visibility: hidden;
}

#graph-root {
  position: absolute;
  width: 100%;
  height: 100%;
}

#graph-scalable {
  width: 100vw;
  height: 100vh;
  overflow: visible;
}

#graph-scalable svg {
  position: absolute;
  overflow: visible;
}

.graph-toolbar {
  position: absolute;
  top: 0;
  font-family: monaco, Monaco, Consolas, "Source Code Pro", "Lucida Console", monospace, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $pane-border;
  color: #888888;
  padding: 12px 20px;
  margin: 0;
  z-index: 13;
  text-align: right;
  background-color: rgba(20, 20, 20, 0.88);
  height: $toolbar-height;
}

.node {
  display: flex;
  flex-direction: column;
  min-width: $node-width;
  max-width: $node-width;
  width: $node-width;
  position: absolute;
  border-radius: 6px;
  -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.88);
  border: 2px solid #333333;
  user-select: none;
  cursor: pointer;
  z-index: 11;
}

.node:hover {
  background-color: rgba(0, 0, 0, 1);
  -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.8);
  box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.8);
  z-index: 12;
}

.node.selected, .node.in-view {
  background-color: rgba(0, 0, 0, 1);
  -webkit-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.8);
  box-shadow: 6px 6px 28px -2px rgba(0, 0, 0, 0.8);
  border: 2px solid #dadada;
  z-index: 14;
}

.node-content {
  padding: 10px 0 8px;
  margin-left: -5px;
  margin-right: -5px;
}

.node-header {
  font-family: monaco, Monaco, Consolas, "Source Code Pro", "Lucida Console", monospace, sans-serif;
  background: rgba(76, 170, 77, 0.1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid #353535;
  padding: 2px 10px 4px 10px;
  color: #d4d4d4;
  text-align: center;
  white-space: pre-wrap;
  font-size: 14px;
}

.node-header-edit {
  padding: 0;
}

.connector {
  fill: none;
  stroke: $connector-color;
  stroke-width: 3;
  stroke-linecap: round;
}

.edge-svg {
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.7));
}

.spline-end, .spline-start {
  fill: #606060;
}

.connector-click-area {
  cursor: pointer;
  fill: none;
  stroke: transparent;
  stroke-width: 20;
}

.connector-click-area:hover + .connector,
.connector:hover, .connector.incomplete {
  stroke: #d5d5d5;
  cursor: pointer;
  stroke-width: 4;
}

.connector.highlight {
  stroke: $graph-red;
  z-index: 11;
}

.connector.transmitting {
  stroke: $primary-graph-color;
  stroke-width: 5;
}

.connector-click-area:hover + .connector.selected,
.connector.selected, .connector.selected:hover {
  stroke: $graph-yellow;
  stroke-width: 6;
}

.node-content li {
  height: 22px;
}

.nodeInputWrapper {
  float: left;
  margin-right: 10px;
}

.nodeOutputWrapper {
  margin-left: 10px;
  float: right;
  text-align: right;
}

.nodeInputList, .nodeOutputList {
  list-style: none;
  padding: 0;
  margin: 0;
  vertical-align: center;
}

.nodeOutputList li {
  cursor: pointer;
  margin-right: -16px;
  padding-right: 16px;
}

.nodeInputList li {
  margin-left: -16px;
  padding-left: 16px;
}

.nodeOutputList li span {
  font-size: 9px;
  text-decoration: none;
  background: transparent;
  box-sizing: border-box;
  color: $primary-graph-color;
}

.nodeOutputList li span i {
  padding-left: 6px;
}

.nodeOutputList li span i.unconnected {
  color: #626262;
}

.nodeOutputList li:hover span {
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.nodeInputList li span {
  cursor: default;
  font-size: 9px;
  color: #858585;
  text-decoration: none;
  background: transparent;
  box-sizing: border-box;
}

.nodeInputList li span.hover {
  color: white;
}

.nodeInputList li .connected-node {
  color: white;
}

.nodeInputList li span i {
  padding-right: 6px;
}

.node.react-draggable-dragging, .react-draggable-transparent-selection,
.dragging, .dragging .node, .dragging .connector, .dragging li, .moving-node * {
  cursor: grabbing;
}

.selecting {
  cursor: crosshair;
}

.dragging .nodeInputList li span.hover {
  vertical-align: top;
  font-size: 14px;
  color: #ffffff;
  margin-top: 2px;
  margin-left: -2px;
  padding-right: 3px;
}

.dragging .nodeInputList li span.hover + span {
  vertical-align: top;
  font-weight: bold;
  color: white;
}

.inputDot {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  background: #e6e6e6;
  border-radius: 50%;
  top: 0;
  right: 3px;
  left: auto;
  border: 2px solid #fff;
}

#selection-box {
  background-color: rgba(200, 200, 200, 0.1);
  position: absolute;
  height: 200px;
  z-index: 14;
  cursor: crosshair;
  border-radius: 3px;
  border: 1px solid rgba(200, 200, 200, 0.3);
}

.zoomer {
  position: absolute;
  bottom: 20px;
  left: 32px;
  width: 225px;
}

// ATTRIBUTE PANE STYLES

$attrs-pad-sides: 16px;

*:focus {
  outline: none !important;
}


.pane-header {
  padding-left: $attrs-pad-sides;
  color: white;
  margin-bottom: 6px;
}

.node-description {
  margin: 0 $attrs-pad-sides 12px;
  font-size: 12px;
}

.attribute-pane {
  color: #888888;
  height: calc(100vh - #{$toolbar-height});
  position: absolute;
  min-width: 140px;
  max-width: 85vw;
  overflow-x: hidden;
  overflow-y: scroll;
  right: 0;
  z-index: 14;
  top: $toolbar-height;
  -webkit-box-shadow: -3px 0px 20px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -3px 0px 20px 3px rgba(0, 0, 0, 0.3);
  box-shadow: -3px 0px 20px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(38, 39, 40, 1);
  border-left: 1px solid $pane-border;
}

.attribute-pane::-webkit-scrollbar {
  display: none;
}

#attr-pane-resizer {
  position: absolute;
  left: 0;
  width: 16px;
  height: 100%;
  cursor: col-resize;
}

.attr-title {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: white;
  margin-bottom: 0;
}

.attr-doc-toggle {
  cursor: pointer;
  font-size: 12px;
  color: $primary-graph-color;
  padding: 8px $attrs-pad-sides;
}

.attr-doc-toggle:hover {
  //noinspection CssInvalidFunction
  color: lighten($primary-graph-color, 10%);
}

.attr-value-row {
  margin-bottom: 8px;
  padding-bottom: 12px;
  border-bottom: 1px dotted rgba(250, 250, 250, 0.2);
}

.attr-doc {
  margin-top: 6px;
  margin-bottom: 8px;
  font-size: 12px;
}

.attr-last {
  border-bottom: none;
}

.attr-list {
  padding: 0 $attrs-pad-sides 12px;
}

.changeable-section-title {
  margin: 20px 0 20px;
  text-underline: white;
  padding-bottom: 4px;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 10px;
  color: rgba(250, 250, 250, 0.8);
  border-bottom: 1px dotted rgba(250, 250, 250, 0.2);
}

.attr-type-indicator {
  cursor: default;
  margin-left: 12px;
  font-size: 11px;
  padding: 3px 8px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: #8b8b8b;
}

.attr-type-indicator:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(250, 250, 250, 0.8)
}

.attr-pop-content-header {
  font-weight: lighter;
  font-size: 12px;
  margin-bottom: 8px;
  color: rgba(250, 250, 250, 0.5)
}

.attr-indicator {
  outline: none;
  font-size: 12px;
  padding: 0 10px;
  cursor: pointer;
  color: rgba(250, 250, 250, 0.4);
  vertical-align: middle;
}

.attr-help {
  cursor: help;
}

.attr-indicator:hover {
  color: rgba(250, 250, 250, 0.8)
}

.attr-help-content {
  overflow-y: scroll;
  max-height: 400px;
  
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    margin-bottom: 10px;;
  }
}

code:not(.syntax-highlighter) {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px 4px 3px 4px;
  color: #bababa;
}

code {
  font-family: monaco, Monaco, Consolas, "Source Code Pro", "Lucida Console", monospace, sans-serif;
}

// JSON VIEWER STYLES

.react-json-view {
  font-size: 12px;

  .copy-to-clipboard-container {
    vertical-align: top;
    display: none;
  }

  .click-to-add {
    display: none;
  }

  .click-to-remove {
    display: none;
  }

  .click-to-edit {
    visibility: hidden;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .click-to-edit-icon {
    padding: 8px 8px 6px 2px;
    cursor: pointer;
    border-radius: 3px;
  }

  .variable-editor {
    outline: none;
    width: 100%;
    margin: 0;
  }

  .variable-value {
    width: 100%;
  }

  .object-key-val {
    &:hover > span > .object-meta-data {
      & > .copy-to-clipboard-container {
        display: inline-block;
      }

      & > .click-to-add {
        display: inline-block;
      }

      & > .click-to-remove {
        display: inline-block;
      }
    }
  }

  .variable-row {
    &:hover {
      & .copy-to-clipboard-container {
        display: inline-block;
      }
    }
  }

  .object-content .variable-row {
    &:hover .click-to-remove {
      display: inline-block;
    }

    &:hover .click-to-edit {
      visibility: visible;
      .click-to-edit-icon {
        //background-color: rgba(0, 0, 0, 0.3);
        color: #d7d7d7;
      }
    }
  }
}

// Variables
$circleDiam: 18px;
$innerCircleDiam: $circleDiam/3;
$rotatingCircleDiam: $circleDiam/5;

$innerCirclePos: ($circleDiam - $innerCircleDiam)/2;
$rotatingCirclePosLeft: ($circleDiam - $innerCircleDiam)/2 - $rotatingCircleDiam;
$rotatingCirclePosTop: $circleDiam/2 - $rotatingCircleDiam/2;

.spinner {
  width: $circleDiam;
  height: $circleDiam;
  position: fixed;
  top: 13px; right: 13px;
  margin: -$circleDiam/2;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 1px rgba(0,0,0,0) inset;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    top: $rotatingCirclePosTop;
    left: $rotatingCirclePosLeft;
    background-color: $connector-color;
    height: $rotatingCircleDiam;
    width: $rotatingCircleDiam;
    animation: spin 1.1s linear infinite;
    border-radius: 50%;
    transform-origin: $innerCirclePos/2 + $rotatingCircleDiam;
  }
}

@keyframes spin { 100% { transform: rotate(360deg); } }

// BluePrint Overrides

.bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.bp3-portal {
  font-family: monaco, Monaco, Consolas, "Source Code Pro", "Lucida Console", monospace, sans-serif;
}

.bp3-popover.bp3-dark .bp3-popover-content {
  font-size: 12px;
  padding: 12px !important;
}

.bp3-slider-track {
  background: $connector-color;
  height: 3px;
  top: 6.5px;
  border-radius: 1.5px;
}

.bp3-slider-label {
  color: $connector-color;
  font-size: 11px;
}

.bp3-slider-handle .bp3-slider-label {
  background: #101011 !important;
  color: #b7b7b7 !important;
}

input {
  font-family: monaco, Monaco, Consolas, "Source Code Pro", "Lucida Console", monospace, sans-serif;
}

$blue1: #8a8a8a;
$blue2: #a1a1a1;
$blue3: #bdbdbd;
$blue4: #d4d4d4;
$blue5: #f0f0f0;

$dark-gray1: darken($bg-color, 12%);
$dark-gray2: darken($bg-color, 5%);
$dark-gray3: $bg-color;
$dark-gray4: lighten($bg-color, 8%);
$dark-gray5: lighten($bg-color, 15%);;;

@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/select/src/blueprint-select.scss";
